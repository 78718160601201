import React from 'react';
import { Typography, List, ListItem } from '@mui/material';
import '../dashboard/styles/Dashboard.css';
import { formatDoubleToPaddedString } from '../../utils/utils';
import { getEmail } from '../../utils/storage';
import { myDebugPrint } from '../../utils/debug';


const StatementsProcessingResults = ({ content, limitsError }) => {
    myDebugPrint(`content of processing result is: ${content}`)

    // Process content to generate list items and calculate total transactions
    const processContent = () => {
        let widgets = [];
        let transactions = 0;

        if (!content || typeof content !== 'object') return [widgets, transactions];

        Object.entries(content).map((item, index) => {
            const widget =
                <Typography variant='body2' sx={{ color: 'black' }}>
                    {item[0]}:
                    {item[1] === "Не удалось прочитать файл" || item[1] === "Этот файл не является .txt файлом"
                        || item[1].includes('Выписка в формате')
                        ? <span style={{ color: 'red' }}>&nbsp;{item[1].replace('{user_email}', getEmail())}</span>
                        : (
                            item[1].includes('Excel')
                                ? <span>
                                    &nbsp;{item[1].replace('{user_email}', getEmail())}
                                </span>
                                : Object.entries(item[1]).map((acc, index) => {
                                    transactions += (acc[1]['transactionsNumberInflows'] + acc[1]['transactionsNumberOutflows']) * 1
                                    return <span key={`${acc[1]['AccNmbr']}-sp-${index}`}>
                                        <br />
                                        <span >
                                            &nbsp;счет №{acc[1]['AccNmbr']}.&nbsp;
                                        </span>
                                        <span >
                                            {acc[1]['DateStart']}-{acc[1]['DateEnd']},
                                            поступления {formatDoubleToPaddedString(
                                                {
                                                    number: acc[1]['Inflow'] * 1, roundDigits: 2
                                                })},
                                            списания {formatDoubleToPaddedString(
                                                {
                                                    number: acc[1]['Outflow'] * 1, roundDigits: 2
                                                })},
                                            транзакций {formatDoubleToPaddedString(
                                                {
                                                    number: (acc[1]['transactionsNumberInflows'] + acc[1]['transactionsNumberOutflows']) * 1, roundDigits: 0
                                                })};
                                        </span>
                                    </span>
                                })
                        )
                    }
                </Typography>
            widgets.push(widget)
        })
        return [widgets, transactions]
    }

    const [listItems, totalTransactions] = processContent();

    return (
        <List>
            {listItems ?
                listItems.map((item, index) => (
                    <ListItem key={index}>
                        {item}
                    </ListItem>
                ))
                : null
            }
            {totalTransactions > 0 &&
                <ListItem>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Общее количество транзакций: {formatDoubleToPaddedString({ number: totalTransactions, roundDigits: 0 })}
                    </Typography>
                </ListItem>
            }
            {
                limitsError &&
                <ListItem>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red' }}>
                        {limitsError}
                    </Typography>
                </ListItem>

            }
        </List>
    );
}

export default StatementsProcessingResults;