import React, { useState } from 'react';
import { Box, Typography, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { formatDate, parseDate } from '../utils/utils'; // Assuming you have a formatDate utility function
import { myDebugPrint } from '../utils/debug';
import { getEmail } from '../utils/storage';

const MyPlan = ({ data }) => {
    const {
        currentPlan,
        paidTill,
        limitsRenewDate,
        standardReportsLimit,
        standardReportsRest,
        standardReportsList,
        detailedReportsLimit,
        detailedReportsRest,
        detailedReportsList,
        cashProjectsLimit,
        cashProjectsRest,
        cashProjectsTransactionsLimit,
        cashProjectsTransactionsRest,
        cashProjectsStorageLimit,
        cashProjectsStorageRest,
    } = data;

    const [standardReportsPage, setStandardReportsPage] = useState(1);
    const [detailedReportsPage, setDetailedReportsPage] = useState(1);
    const itemsPerPage = 20;

    const handleStandardReportsPageChange = (event, value) => {
        setStandardReportsPage(value);
    };

    const handleDetailedReportsPageChange = (event, value) => {
        setDetailedReportsPage(value);
    };

    const renderListWithPagination = (items, page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return items.slice(startIndex, endIndex);
    };

    const today = new Date();
    const paidTillDate = paidTill ? parseDate({ dateString: paidTill, format: 'yyyy-MM-dd' }) : null;

    const myEmail = getEmail();

    return (
        <Box>
            <Typography variant="body1">
                Email: {myEmail}
            </Typography>
            {(!paidTill || !paidTillDate || paidTillDate < today) &&
                <Box>
                    <Typography variant="h3" className='h2BusinessDetails' sx={{ marginTop: '25px' }}>
                        О тарифе
                        <Typography variant="body1">Текущий тариф: {currentPlan ?? 'Триал'}</Typography>
                        <Typography variant="body1">Срок действия: <span
                            style={{ color: 'red', fontWeight: 'bold' }}>{currentPlan ? 'оплаченный' : 'пробный'} период закончился {paidTill ? formatDate(paidTill) : ''}</span>
                        </Typography>
                    </Typography>
                </Box>
            }
            {paidTillDate && paidTillDate >= today &&
                <Box>
                    <Typography variant="h3" className='h2BusinessDetails' sx={{ marginTop: '25px' }}>
                        О тарифе
                        <Typography variant="body1">Текущий тариф: {currentPlan}</Typography>
                        <Typography variant="body1">Срок действия: {formatDate(paidTill)}</Typography>
                    </Typography>

                    <Typography variant="h3" className='h2BusinessDetails' sx={{ marginTop: '25px' }}>
                        Остатки по тарифу
                    </Typography>

                    {paidTill > limitsRenewDate ?
                        <Typography variant="body1">
                            Лимиты на анализ новых транзакций и проверку контрагентов восстановятся: {formatDate(limitsRenewDate)}
                            <br />
                            Лимит на количество транзакций в базе и архиве учитывает все транзакции, загруженные за все время и не удаленные из сервиса.
                        </Typography>
                        :
                        <Box></Box>
                    }
                    <Grid container spacing={3} sx={{ mt: 0, paddingTop: 0 }}>
                        <Grid item xs={12} md={3} sx={{ mt: 0, paddingTop: '10px !important' }}>
                            <Card>
                                <CardHeader
                                    title={<span>Анализ выписок</span>}
                                    subheader={<span>новых транзакций</span>}
                                >
                                </CardHeader>
                                <CardContent>
                                    <LinearProgress
                                        variant="determinate"
                                        value={cashProjectsTransactionsLimit == 0 ? 0
                                            : ((cashProjectsTransactionsRest) / cashProjectsTransactionsLimit) * 100}
                                        sx={{
                                            marginBottom: '10px',
                                            background: 'white',
                                        }}
                                    />
                                    <Typography variant="body2">
                                        Осталось {cashProjectsTransactionsRest} из {cashProjectsTransactionsLimit}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 0, paddingTop: '10px !important' }}>
                            <Card>
                                <CardHeader
                                    title={<span>Анализ выписок</span>}
                                    subheader={<span>транзакций в базе и архиве</span>}
                                >
                                </CardHeader>
                                <CardContent>
                                    <LinearProgress
                                        variant="determinate"
                                        value={cashProjectsStorageLimit == 0 ? 0 : ((cashProjectsStorageRest) / cashProjectsStorageLimit) * 100}
                                        sx={{
                                            marginBottom: '10px',
                                            background: 'white',
                                        }}
                                    />
                                    <Typography variant="body2">
                                        Осталось {cashProjectsStorageRest} из {cashProjectsStorageLimit}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 0, paddingTop: '10px !important' }}>
                            <Card>
                                <CardHeader
                                    title='Проверка контрагентов'
                                    subheader='простая'>
                                </CardHeader>
                                <CardContent>
                                    <LinearProgress
                                        variant="determinate"
                                        value={standardReportsLimit == 0 ? 0 : ((standardReportsRest) / standardReportsLimit) * 100}
                                        sx={{
                                            marginBottom: '10px',
                                            background: 'white',
                                        }}
                                    />
                                    <Typography variant="body2">
                                        Осталось {standardReportsRest} из {standardReportsLimit}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 0, paddingTop: '10px !important' }}>
                            <Card>
                                <CardHeader
                                    title='Проверка контрагентов'
                                    subheader='полная'>
                                </CardHeader>
                                <CardContent>
                                    <LinearProgress
                                        variant="determinate"
                                        value={detailedReportsLimit == 0 ? 0 : ((detailedReportsRest) / detailedReportsLimit) * 100}
                                        sx={{
                                            marginBottom: '10px',
                                            background: 'white',
                                        }}
                                    />
                                    <Typography variant="body2">
                                        Осталось {detailedReportsRest} из {detailedReportsLimit}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>                    </Grid>

                    {
                        standardReportsList &&
                        <Box>
                            <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Standard Reports List</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderListWithPagination(standardReportsList, standardReportsPage).map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination
                                count={Math.ceil(standardReportsList.length / itemsPerPage)}
                                page={standardReportsPage}
                                onChange={handleStandardReportsPageChange}
                                sx={{ marginTop: '10px' }}
                            />
                        </Box>
                    }



                    {
                        detailedReportsList &&
                        <Box>
                            <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Detailed Reports List</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderListWithPagination(detailedReportsList, detailedReportsPage).map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination
                                count={Math.ceil(detailedReportsList.length / itemsPerPage)}
                                page={detailedReportsPage}
                                onChange={handleDetailedReportsPageChange}
                                sx={{ marginTop: '10px' }}
                            />
                        </Box>
                    }
                </Box>
            }
        </Box >
    );
};

export default MyPlan;
