import React from 'react';
import { Box, } from '@mui/material';
import TransactionsCard from '../cards/TransactionsCard';
import { formatDateToGivenFormat } from '../../../utils/utils';

const ReportCashTransactions = ({ cashProject, updateCashProjectPayment,
    filteredDateStart, filteredDateEnd, filteringInProgress, onFilterDates, }) => {


    return (
        <Box>
            <TransactionsCard
                projectId={cashProject.projectId}
                currencyCode='810'
                updateCashProjectPayment={updateCashProjectPayment}
                minDate={formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                maxDate={formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                filteredDateStart={filteredDateStart}
                filteredDateEnd={filteredDateEnd}
                filteringInProgress={filteringInProgress}
                onFilterDates={onFilterDates}
                counterparties_list={cashProject.counterparties_list}
            />
        </Box>
    )

}

export default ReportCashTransactions