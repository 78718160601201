import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';
import CircleLoader from 'react-spinners/CircleLoader';
import '../dashboard/styles/Dashboard.css';
import { formatDoubleToPaddedString } from '../../utils/utils';
import MyFileUpload from '../forms/MyFileUpload';
import StatementsProcessingResults from './StatementsProcessingResults';


const CashUploader = ({ projectName, projectId,
    processingResults, handleUpload, isUploading,
    fetchingStatus, error, tagUploadInProgress }) => {

    return (
        <Box>
            {tagUploadInProgress}
            {((!processingResults || !processingResults.content)) &&
                <MyFileUpload
                    dragDropCTA={
                        <span>
                            Прикрепите файлы с выписками:
                            <br />выгрузка из банка в txt для 1С - мгновенная обработка,
                            <br />выписки Excel (xls / xlsx) - обработка занимает от нескольких минут до 1 рабочего дня,
                            <br />выписки Word (doc, docx, rtf) - обработка занимает от нескольких минут до 1 рабочего дня бесплатно или от 3 рублей / страница (вы получите оценку перед обработкой),
                            <br />выписки PDF/сканы в PDF - обработка занимает от 1 рабочего дня, стоимость от 3 рублей/страница  (вы получите оценку перед обработкой)
                        </span>
                    }
                    handleUpload={handleUpload}
                    isUploading={isUploading}
                />
            }
            {!(!isUploading) &&
                <Box>
                    {fetchingStatus &&
                        <Typography variant='body2'>
                            {fetchingStatus}
                        </Typography>
                    }
                    <CircleLoader color="#8A4B9A" className='spinner' size={35} />
                </Box>
            }
            {!(!error) &&
                <Typography variant='body2' sx={{ color: 'res' }} >
                    {error}
                </Typography>
            }
            {!(!processingResults) && processingResults.content &&
                <Box mt={2}>
                    <Typography variant="h6">Предварителные результаты загрузки файлов</Typography>
                    {processingResults.content &&
                        <StatementsProcessingResults
                            content={processingResults.content}
                            limitsError={processingResults.limitsError}
                        />

                        // <List>
                        //     {Object.entries().map((item, index) => (
                        //         <ListItem key={`${item[0]}-${index}`}>
                        //             <Typography variant='body2' sx={{ color: 'black' }}>
                        //                 {item[0]}:
                        //                 {item[1] === "Не удалось прочитать файл" || item[1] === "Этот файл не является .txt файлом"
                        //                     ? <span style={{ color: 'red' }}>&nbsp;{item[1]}</span>
                        //                     : Object.entries(item[1]).map((acc, index) => (
                        //                         <span key={`${acc[1]['AccNmbr']}-sp-${index}`}>
                        //                             <br />
                        //                             <span >
                        //                                 &nbsp;счет №{acc[1]['AccNmbr']}.&nbsp;
                        //                             </span>
                        //                             <span >
                        //                                 {acc[1]['DateStart']}-{acc[1]['DateEnd']},
                        //                                 поступления {formatDoubleToPaddedString(
                        //                                     {
                        //                                         number: acc[1]['Inflow'] * 1, roundDigits: 2
                        //                                     })},
                        //                                 списания {formatDoubleToPaddedString(
                        //                                     {
                        //                                         number: acc[1]['Outflow'] * 1, roundDigits: 2
                        //                                     })};
                        //                             </span>
                        //                         </span>
                        //                     ))
                        //                 }
                        //             </Typography>
                        //         </ListItem>
                        //     )
                        //     )}
                        // </List>

                    }
                </Box>
            }
        </Box>);
}


export default CashUploader;
