import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import DashboardSkeleton from '../components/dashboard/DashboardSkeleton';
import { myDebugPrint } from '../utils/debug';
import ApiManager from '../utils/api';
import { useSnackbar } from '../context/SnackbarContext';
import MyFileUpload from '../components/forms/MyFileUpload';
import JSZip from 'jszip';
import { Box, Typography, List, ListItem } from '@mui/material';
import CircleLoader from 'react-spinners/CircleLoader';
import '../components/dashboard/styles/Dashboard.css';
import { formatDoubleToPaddedString } from '../utils/utils';
import CashUploader from '../components/cashAnalysis/CashUploader';
import ReportCashProject from '../components/cashAnalysis/ReportCashProject';
import CashProjectsList from '../components/CashProjectList';


const CashAnalysis = () => {
    const [searchParams] = useSearchParams();
    const [projectId, setProjectId] = useState(searchParams.get('project') ?? 'new');
    const [projectName, setProjectName] = useState('');
    const [uploadMode, setUploadMode] = useState('replace');
    const [processingResults, setProcessingResults] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [fetchingStatus, setFetchingStatus] = useState();
    const [error, setError] = useState();
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        if (projectId != 'new') {
            if (!searchParams.get('project')) {
                setProjectId('new');
                setProjectName('');
                setUploadMode('replace');
                setProcessingResults({});
                setIsUploading(false);
                setFetchingStatus();
                setError();
            } else {
                if (projectId != searchParams.get('project')) {
                    setProjectName('');
                    setUploadMode('replace');
                    setProcessingResults({});
                    setIsUploading(false);
                    setFetchingStatus();
                    setError();
                    setProjectId(searchParams.get('project'))
                }
            }
        } else {
            if (projectId != searchParams.get('project')) {
                setProjectName('');
                setUploadMode('replace');
                setProcessingResults({});
                setIsUploading(false);
                setFetchingStatus();
                setError();
                setProjectId(searchParams.get('project'))
            }
        }
    }, [searchParams]);


    const handleSearch = async (query) => {
        if (!query || query.length < 2) { return false; }

        navigate({
            pathname: "/search",
            search: createSearchParams({
                q: query
            }).toString(),
        });
    };


    const handleUpload = async (acceptedFiles) => {
        setIsUploading(true);
        setError();
        setFetchingStatus('Готовим файлы к загрузке')
        setProcessingResults(null);
        const zip = new JSZip();
        const readFile = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve({ name: file.name, data: e.target.result });
            reader.onerror = (e) => reject(e);
            reader.readAsArrayBuffer(file);
        });

        try {

            const filesData = await Promise.all(acceptedFiles.map(readFile));
            filesData.forEach(file => {
                zip.file(file.name, file.data);
            });

            const content = await zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: {
                    level: 9
                },
            });
            setFetchingStatus('Отправляем данные на сервер...')

            const response = await ApiManager.cashUpload({
                projectId: projectId,
                projectName: projectName == '' ? null : projectName,
                mode: uploadMode,
                statements: content,
            });
            myDebugPrint(`response: ${JSON.stringify(response)}`);
            if (response && response.data) {
                let result = {};
                if (response.data.project) {
                    // TODO: show links to a project page
                    setProjectId(response.data.project['id']);
                    setProjectName(response.data.project['name']);
                    result.project = {
                        'id': response.data.project['id'],
                        'name': response.data.project['name'],
                    }
                }
                if (response.data.content) {
                    result.content = response.data.content;
                    { myDebugPrint(`result.content: ${JSON.stringify(result.content)}`) }
                }
                if (response.data.errorsCount === 0
                    || response.data.errorsCount === '0'
                    || response.data.errorsCount) {
                    result.errors = response.data.errorsCount;
                }
                if (response.data.validCount === 0
                    || response.data.validCount === '0'
                    || response.data.validCount) {
                    result.validCount = response.data.validCount;
                }
                if (response.data.limitsError) {
                    result.limitsError = response.data.limitsError;
                }
                if (result && (result.content || result.project || result.errors || result.limitsError)) {
                    setProcessingResults({
                        'content': result.content ? result.content : null,
                        'project': result.project ? result.project : null,
                        'errors': result.errors ? result.errors : null,
                        'validCount': result.validCount ? result.validCount : null,
                        'limitsError': result.limitsError ? result.limitsError : null,
                    });
                }
                setError();
                setIsUploading(false);
                setFetchingStatus()
            } else {
                setError('Не удалось отправить файлы на сервер - размер файла превосходит лимит. Отправьте выписки вашему менеджеру.');
                showSnackbar(error, 'Не удалось отправить файлы на сервер - размер файла превосходит лимит. Отправьте выписки вашему менеджеру.');
                setIsUploading(false);
                setFetchingStatus()
            }
        } catch (error) {
            myDebugPrint(`error on proccessing project: ${JSON.stringify(error)}`)
            showSnackbar('Не удалось отправить файлы на сервер. Обновите страницу. Если ошибка повторится, напишите вашему менеджеру или на info@brpadvice.ru', 'error');
            setError(error);
            setIsUploading(false);
            setFetchingStatus()
            myDebugPrint('Error uploading files:', error);
        }
    };

    // const handleProjectChange = (newProjectId) => {
    //     if (searchParams.get('project') === newProjectId) { return };
    //     setProjectId(newProjectId);
    //     navigate({
    //         pathname: `/cash`,
    //         search: createSearchParams({ project: newProjectId }).toString(),
    //     });
    // };

    const handleError = ({ errorMessage }) => {
        showSnackbar(errorMessage, 'error');
    };


    return (
        <DashboardSkeleton
            handleSearch={handleSearch}
            initialQuery={''}
            isSearching={false}
            isCorp={false}
        >
            {(searchParams.get('project') == null || searchParams.get('project') == 'new') ?
                <Box>
                    <Typography variant="h2" className='h2BusinessDetails'>
                        {!projectName ? 'Анализ выписок: новый проект' : projectName}
                    </Typography>
                    {!projectName &&
                        <Typography variant="body2">
                            Автоматический анализ работает с выгрузками для 1С (мгновенная обработка) и Excel (обработка занимает от нескольких минут до 1 рабочего дня).
                            <br />
                            Чтобы провести анализ по выпискам pdf или в других форматах,
                            отправьте их на info@brpadvice.ru
                        </Typography>
                    }
                    <CashUploader
                        projectName={projectName}
                        projectId={projectId}
                        processingResults={processingResults}
                        handleUpload={handleUpload}
                        isUploading={isUploading}
                        fetchingStatus={fetchingStatus}
                        error={error}
                        tagUploadInProgress=
                        {!(!projectName) && projectId
                            && processingResults && processingResults.validCount
                            && processingResults.validCount > 0 &&
                            <Typography variant="body2">
                                Данные выписок загружены и анализ уже начался.
                                Это займет несколько минут.
                                <br />
                                Чтобы перейти на страницу проекта <a
                                    className='pseudoHrefWithColor'
                                    href={'\cash?project=' + projectId}
                                >
                                    нажмите здесь</a>.
                            </Typography>
                        }
                    />
                    <Typography variant="h2" className='h2BusinessDetails' sx={{ marginTop: '70px' }}>
                        {!projectName ? 'Мои ' : 'Другие '}проекты
                    </Typography>
                    <CashProjectsList />
                </Box>
                : <ReportCashProject
                    projectId={projectId}
                    handleError={handleError}
                    cashUploader={
                        <CashUploader
                            projectName={projectName}
                            projectId={projectId}
                            processingResults={processingResults}
                            handleUpload={handleUpload}
                            isUploading={isUploading}
                            fetchingStatus={fetchingStatus}
                            error={error}
                            tagUploadInProgress=
                            {!(!projectName) && projectId
                                && processingResults && processingResults.validCount
                                && processingResults.validCount > 0 &&
                                <Typography variant="body2">
                                    Данные выписок загружены и анализ уже начался.
                                    Это займет несколько минут.
                                    <br />
                                    Чтобы перейти на страницу проекта <a
                                        className='pseudoHrefWithColor'
                                        href={'\cash?project=' + projectId}
                                    >
                                        нажмите здесь</a>.
                                </Typography>
                            }
                        />
                    }
                />
            }

        </DashboardSkeleton>
    );
};

export default CashAnalysis;
