import React, { useState, useEffect } from 'react';
import {
    Grid, Card, CardContent, CardHeader, Button, ThemeProvider, IconButton, Tooltip, Box,
    TextField, Autocomplete, useMediaQuery, useTheme,
    Divider
} from '@mui/material';
import DoneAll from '@mui/icons-material/DoneAll';
import ToggleIconButton from './ToggleIconButton';
import { slicerTheme } from '../../theme';
import CircleLoader from 'react-spinners/CircleLoader';

const SlicerAutocomplete = ({
    title, subheader, itemsDict, keyForLabel = 'title', keyForId = 'id',
    initialSelectedItems = [], onToggle, onSelectAll, onDeselectAll,
    onApply,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonIsActive, setButtonIsActive] = useState(false);

    // Limit the displayed suggestions to 100 for performance
    const limitedSuggestions = itemsDict;// ? itemsDict.slice(0, 100) : [];

    useEffect(() => {
        setSuggestions(limitedSuggestions);
    }, [itemsDict]);
    useEffect(() => {
        checkForChanges(selectedItems);
    }, [initialSelectedItems]);

    const handleSelect = (event, newValue) => {
        if (!newValue) return

        const selectedId = itemsDict[newValue];
        const exist = !!selectedItems.find(item => item == newValue)
        if (exist) {
            return
        }
        const newSelected = [...selectedItems, newValue]
        setSelectedItems(newSelected)
        checkForChanges(newSelected)
    };

    const handleRemove = (item) => {
        const updatedSelectedItems = selectedItems.filter(selected => selected !== item);
        setSelectedItems(updatedSelectedItems);
        onToggle && onToggle(item);
        checkForChanges(updatedSelectedItems)
    };

    const checkForChanges = (newSelected) => {
        if ((newSelected ?? []).length != (initialSelectedItems ?? []).length) {
            setButtonIsActive(true)
            return;
        }
        if ((newSelected ?? []).length == 0) {
            setButtonIsActive(false)
            return;
        }

        let hasChanged = false;
        let exist = true
        newSelected.every(newItem => {
            exist = initialSelectedItems.includes(newItem)
            if (!exist) {
                hasChanged = true;
                // break the loop returning false for .every
                return false
            }
            // continie the loop returning true for .every
            return true
        })
        setButtonIsActive(hasChanged)
        return;
    }

    const applyFilter = async () => {
        setIsLoading(true);
        try {
            let needleList = []
            if ((selectedItems ?? []).length > 0) {
                selectedItems.forEach(item => needleList.push(item))
            }
            await onApply(needleList);
        } catch {

        }
        setIsLoading(false);
    }

    return (
        <ThemeProvider theme={slicerTheme}>
            <Card style={{ width: '100%' }} variant='slicer'>
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={
                        <ToggleIconButton
                            icon={<DoneAll />}
                            isOn={limitedSuggestions.length === selectedItems.length}
                            onSelectAll={() => {
                                // const allItems = Object.values(itemsDict).slice(0, 100);
                                // setSelectedItems(allItems);
                                // onSelectAll && onSelectAll(allItems);
                                setSelectedItems([]);
                                onDeselectAll && onDeselectAll();
                            }}
                            onDeselectAll={() => {
                                setSelectedItems([]);
                                onDeselectAll && onDeselectAll();
                            }}
                        />
                    }
                />
                <CardContent>
                    <Autocomplete
                        options={limitedSuggestions}
                        getOptionLabel={(option) => option}
                        onChange={handleSelect}
                        renderInput={(params) => (
                            <TextField {...params} label="Поиск контрагентов" variant="outlined" />
                        )}
                        ListboxProps={{
                            style: {
                                maxHeight: '150px',
                                overflowY: 'auto'
                            }
                        }}
                        sx={{ mb: 2 }}
                    />
                    <Box>
                        <Grid container spacing={0.2}>
                            {selectedItems.map((item) => (
                                <Grid item xs={6} sm={4} md={3} key={item}>
                                    <Tooltip title={item} arrow>
                                        <Button
                                            variant="slicerOn"
                                            onClick={() => handleRemove(item)}
                                            fullWidth
                                        >
                                            {item}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {buttonIsActive &&
                        <Box>
                            <Divider sx={{ my: '15px' }} />
                            {/* Filter Button */}
                            <Box>
                                {isLoading &&
                                    <Box sx={{
                                        width: isSmallScreen ? '100%' : '45px'
                                    }}>
                                        <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                                    </Box>
                                }
                                {!isLoading &&
                                    <Button
                                        variant="slicerOn"
                                        onClick={() => applyFilter()}
                                        sx={{ width: isSmallScreen ? '100%' : 'auto', fontSize: 'small' }} // Full width on small screens
                                    >
                                        Применить
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default SlicerAutocomplete;
