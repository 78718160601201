import React, { useState, useEffect } from 'react';
import {
    Grid, Card, CardContent, CardHeader, Button, ThemeProvider, Tooltip, Box,
    TextField, Divider, IconButton, useMediaQuery, useTheme,
} from '@mui/material';
import DoneAll from '@mui/icons-material/DoneAll';
import CircleLoader from 'react-spinners/CircleLoader';
import { slicerTheme } from '../../theme';

const SlicerListOfTerms = ({
    title, subheader,
    initialTerms = [], onApply, onClearAll
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [terms, setTerms] = useState(initialTerms);
    const [currentInput, setCurrentInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [buttonIsActive, setButtonIsActive] = useState(false);

    useEffect(() => {
        checkForChanges(terms);
    }, [initialTerms]);

    // Add a term to the list
    const handleAddTerm = () => {
        const trimmedInput = currentInput.trim().toLowerCase();
        if (!trimmedInput) return;
        if (terms.includes(trimmedInput)) {
            setCurrentInput('');
            checkForChanges(updatedTerms);
            return
        };
        const updatedTerms = [...terms, trimmedInput];
        setTerms(updatedTerms);
        setCurrentInput('');
        checkForChanges(updatedTerms);
    };

    // Remove a term from the list
    const handleRemoveTerm = (term) => {
        const updatedTerms = terms.filter(item => item !== term);
        setTerms(updatedTerms);
        checkForChanges(updatedTerms);
    };

    // Clear all terms
    const handleClearAll = () => {
        setTerms([]);
        setButtonIsActive(false);
        onClearAll && onClearAll();
    };

    // Check for changes to enable/disable "Apply" button
    const checkForChanges = (newTerms) => {
        if ((newTerms ?? []).length !== (initialTerms ?? []).length ||
            !newTerms.every(term => initialTerms.includes(term))) {
            setButtonIsActive(true);
        } else {
            setButtonIsActive(false);
        }
    };

    // Apply the terms
    const applyFilter = async () => {
        setIsLoading(true);
        try {
            await onApply(terms);
        } catch {
            // Handle errors as needed
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={slicerTheme}>
            <Card style={{ width: '100%' }} variant='slicer'>
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={
                        <Tooltip title="Очистить всё">
                            <IconButton onClick={handleClearAll}>
                                <DoneAll />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <CardContent>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <TextField
                            label="Введите термин"
                            variant="outlined"
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleAddTerm();
                            }}
                            fullWidth
                        />
                        <Button
                            variant="contained"
                            onClick={handleAddTerm}
                            disabled={!currentInput.trim()}
                        >
                            Добавить
                        </Button>
                    </Box>
                    <Box>
                        <Grid container spacing={0.5}>
                            {terms.map((term, index) => (
                                <Grid item xs={6} sm={4} md={3} key={index}>
                                    <Tooltip title={term} arrow>
                                        <Button
                                            variant="slicerOn"
                                            onClick={() => handleRemoveTerm(term)}
                                            fullWidth
                                        >
                                            {term}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {buttonIsActive &&
                        <Box>
                            <Divider sx={{ my: '15px' }} />
                            <Box>
                                {isLoading &&
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                                    </Box>
                                }
                                {!isLoading &&
                                    <Button
                                        variant="slicerOn"
                                        onClick={() => applyFilter()}
                                        sx={{ width: isSmallScreen ? '100%' : 'auto', fontSize: 'small' }} // Full width on small screens
                                    >
                                        Применить
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default SlicerListOfTerms;